import React, {useEffect, useState} from 'react'
import Blog from './components/blogSingle/blog';
import {useParams} from 'react-router-dom'
import {apiCall} from "../api/apiCall";

export default function blogDetail(props){
    const params = useParams()
    const [blogData, setBlogData] = useState(null)
    const getBlogDetail = async () => {
        setBlogData(await apiCall('/blog/' + params.id + '/detail', {}))
    }

    useEffect(() => {
        getBlogDetail()
    },[params.id])
    return (
        <>
            <div className="inner-page">
                <div className="faq-page pt-75 pb-0">
                    <div className="blog-page">
                        <div className="container">
                            <div className="row">
                                {
                                    blogData ?
                                        <Blog blogData={blogData} />
                                        : ('')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
