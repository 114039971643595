import React, {useEffect, useState} from 'react'
import RecentBlog from './common/recentBlog';
import {apiCall} from '../api/apiCall'
import { BLOG_LIST } from '../api/apiRoutes'
import BlogSM from './common/blogSM'
import BlogLG from './common/blogLG'

export default function blogList(){
    const [blogs, setBlogs] = useState([])
    const [firstBlog, setFirstBlog] = useState(null)
    const [secondBlog, setSecondBlog] = useState([])

    const getBlogList = async () => {
        const blogList = await apiCall(BLOG_LIST,{})
        const s_value = [];
        const rest_value = [];
        if (blogList.length) {
            blogList.map((data, key) => {
                if (key === 0) {
                    setFirstBlog(data)
                } if (key === 1 || key === 2) {
                    s_value.push(data)
                }
                if (key > 2) {
                    rest_value.push(data)
                }
            })
            setSecondBlog(s_value)
            setBlogs(rest_value)
        }
    }

    useEffect(() => {
        getBlogList()
    },[])

    return (
        <>
            <div className="inner-page">
                <div className="faq-page pt-75 pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="hadding-area">
                                    <h2>Latest News / Stories</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blog-page">
                        <div className="container">
                            <div className="row">
                                <aside className="col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                    <div className="latest-news pb-75">
                                        <div className="row">
                                            {
                                                firstBlog ?
                                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                        <BlogLG blog={firstBlog}/>
                                                    </div>

                                                    : ('')
                                            }
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                {
                                                    secondBlog.length ?
                                                        secondBlog.map((blog, key) => {
                                                            return <BlogSM blog={blog} key={key}/>
                                                        })
                                                        : ('')
                                                }
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                {
                                                    blogs.length ?
                                                        blogs.map((blog, key) => {
                                                            return <BlogSM blog={blog} key={key}/>
                                                        }) : ('')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <aside className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <RecentBlog />
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
